import { SommerlesSettings } from "@libry-content/types";
import { SanityClient } from "@sanity/client";
import differenceInCalendarMonths from "date-fns/differenceInCalendarMonths";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";

import { filters } from "./filters";

export const isSommerlesSeason = (sommerlesSettings: SommerlesSettings | null, startMonthsBefore = 0): boolean => {
  const now = new Date();

  if (!sommerlesSettings?.startDate || !sommerlesSettings?.endDate) {
    console.error("Could not determine sommerles start and end date");
    return false;
  }

  const thisYear = now.getFullYear();
  const startDate = new Date(`${thisYear}-${sommerlesSettings.startDate}`);
  const endDate = new Date(`${thisYear}-${sommerlesSettings.endDate}`);

  const monthsUntilStart = differenceInCalendarMonths(startDate, now);
  const daysSinceEnd = differenceInCalendarDays(now, endDate);

  return monthsUntilStart <= startMonthsBefore && daysSinceEnd < 1;
};

export const getIsSommerlesSeason = async (client: SanityClient, startMonthsBefore = 0): Promise<boolean> => {
  const query = `*[ _type == "sommerlesSettings" && ${filters.isNotDraft} ][0]`;
  const sommerlesSettings = await client.fetch<SommerlesSettings | null>(query);

  return isSommerlesSeason(sommerlesSettings, startMonthsBefore);
};
