import { withErrorBoundary } from "@biblioteksentralen/js-utils";
import { getSiteUrl, withoutUndefinedValues, splashSiteUrl } from "@libry-content/common";
import { ts } from "@libry-content/localization";
import { DecorativeImage, ImageWithMetadata, Site } from "@libry-content/types";
import Head from "next/head";
import React, { useEffect } from "react";
import type { Thing } from "schema-dts";
import { loggError } from "../utils/logging";
import { imageUrlBuilder } from "../utils/sanity/client";
import { useSiteContext } from "./layout/SiteContext";
import { getSchemaOrgImageUrl } from "./library/utils";
import { ResolvedSite } from "./site/sanityQuery";
import { getSchemaOrgSiteImage } from "./site/utils";

interface Props {
  title: string;
  description: string;
  path: string;
  externalImageUrl?: string;
  sanityImage?: DecorativeImage | ImageWithMetadata;
  icon?: string;
  schemaOrgThing?: Omit<Thing, string>;
  disableImageCrop?: boolean;
}

export function getTitle(title: string, site?: Pick<Site, "name">): string {
  if (!site) return title;
  const siteName = ts(site.name) ?? "";
  if (!title.length) return siteName;
  if (title === siteName) return title;
  return `${title} | ${siteName}`;
}

function SEO(props: Props) {
  const { site } = useSiteContext();

  const siteImg = getSchemaOrgSiteImage(site);
  const iconUrl = siteImg ? getSchemaOrgImageUrl(siteImg) : undefined;

  const imageUrl = props.externalImageUrl ?? getImageUrl(props.sanityImage, siteImg, props.disableImageCrop);

  const schemaOrgData = props.schemaOrgThing
    ? {
        "@context": "https://schema.org/",
        name: props.title,
        description: props.description,
        image: imageUrl,
        ...withoutUndefinedValues(props.schemaOrgThing),
      }
    : undefined;

  useEffect(() => {
    if (!/^\//.test(props.path)) {
      loggError(new Error("path må starte med /"));
    }
  }, [props.path]);

  const canonical = `${site ? getSiteUrl(site) : splashSiteUrl}${props.path}`;
  const title = getTitle(props.title, site);
  return (
    <Head>
      <title>{title}</title>
      <link rel="icon" type="image/png" href={props.icon ?? iconUrl} />
      <link rel="canonical" href={canonical} />
      <meta name="description" content={props.description} />
      <meta property="image" content={imageUrl} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={canonical} />
      <meta property="og:description" content={props.description} />
      <meta property="og:site_name" content={ts(site?.name)} />
      <meta property="og:image" content={imageUrl} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={props.description} />
      <meta name="twitter:image" content={imageUrl} />

      {schemaOrgData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaOrgData, null, 2) }}
        />
      )}
    </Head>
  );
}

const seoImageheight = 630;
const seoImageWidth = 1200;

/**
 *
 * @param disableCrop Facebook handles both tall and wide images by autoplacing them left or above,
 * but this is not the case in general (example: LinkedIn, which also uses open graph). Therefore we
 * add space on the sides when not cropping, to preserve format.
 */
function getImageUrl(
  image: Props["sanityImage"],
  fallbackImage?: ResolvedSite["logo"] | ResolvedSite["coatOfArms"],
  disableCrop?: boolean
) {
  const withFallbackImage = !image?.asset;

  let urlBuilder = imageUrlBuilder(withFallbackImage ? fallbackImage : image)
    ?.quality(85)
    .bg("eee") // Use a background that approximates the background gradient we have in SanityImage
    .format("jpg");

  if (disableCrop) {
    // Without ignoreImageParams the builder adds rect() when both height and width are specified
    urlBuilder = urlBuilder?.ignoreImageParams().size(seoImageWidth, seoImageheight).fit("fill");
  } else {
    urlBuilder = urlBuilder?.size(seoImageWidth, seoImageheight);
  }

  // Fallback-image using logo or coatOfArms, but padded so it can handle some cropping
  if (withFallbackImage) urlBuilder = urlBuilder?.ignoreImageParams().fit("fill").pad(60);

  return urlBuilder?.url() ?? "";
}

export default withErrorBoundary(SEO, "SEO");
